var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', _vm._l(_vm.boards, function (board, idx) {
    var _board$files$, _board$writer, _vm$user, _board$writer2, _vm$user2;
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "link-item_btn-wrap mb-4 mb-lg-16"
    }, [_c('v-img', {
      staticClass: "w-100 mb-8 mb-lg-0",
      attrs: {
        "aspect-ratio": 282 / 388,
        "src": board === null || board === void 0 ? void 0 : board.thumb
      }
    }), _c('v-btn', {
      staticClass: "view-btn w-100 mw-lg-120px px-8 px-lg-16",
      attrs: {
        "to": board === null || board === void 0 ? void 0 : (_board$files$ = board.files[0]) === null || _board$files$ === void 0 ? void 0 : _board$files$.url,
        "target": "_blank",
        "outlined": "",
        "tile": ""
      }
    }, [_c('span', {
      staticClass: "d-inline-block text-center",
      staticStyle: {
        "width": "calc(100% - 18px)"
      }
    }, [_vm._v("보러가기")]), _c('i', {
      staticClass: "icon icon-right-arrow-black ml-2 ml-lg-4"
    })])], 1), _c('v-btn', {
      staticClass: "mw-100 download-btn",
      attrs: {
        "outlined": "",
        "tile": "",
        "block": "",
        "height": "auto",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          var _board$files$2;
          return _vm.download(`${board === null || board === void 0 ? void 0 : (_board$files$2 = board.files[0]) === null || _board$files$2 === void 0 ? void 0 : _board$files$2.url}`, `${board === null || board === void 0 ? void 0 : board.subject}`);
        }
      }
    }, [_c('span', {
      staticClass: "download-btn__tit"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")]), _c('i', {
      staticClass: "icon icon-right-arrow-primary ml-2 ml-lg-4"
    })]), _c('v-divider'), _c('div', {
      staticClass: "mt-4"
    }, [_c('v-row', [_c('v-col', [(board === null || board === void 0 ? void 0 : (_board$writer = board.writer) === null || _board$writer === void 0 ? void 0 : _board$writer._id) === ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user._id) ? _c('v-btn', {
      attrs: {
        "block": "",
        "outlined": "",
        "color": "grey-9"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/community/news/${board._id}?mode=input`);
        }
      }
    }, [_vm._v("수정하기")]) : _vm._e()], 1), _c('v-col', [(board === null || board === void 0 ? void 0 : (_board$writer2 = board.writer) === null || _board$writer2 === void 0 ? void 0 : _board$writer2._id) === ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2._id) ? _c('v-btn', {
      attrs: {
        "block": "",
        "outlined": "",
        "color": "grey-9"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteBoard(board);
        }
      }
    }, [_vm._v("삭제하기")]) : _vm._e()], 1)], 1)], 1)], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), !!_vm.write ? _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`?mode=input`);
      }
    }
  }, [_vm._v(" 글쓰기 ")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }