var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소통과 참여",
      "tabActive": "산하시설소식",
      "bg": "/images/sub/visual/sv5.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_vm.$route.query.mode === 'input' ? _c('news-write') : _c('news-list')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }