var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "column": "",
      "justify-center": ""
    }
  }, [_c('div', {
    staticClass: "mb-40 mb-lg-60 text-center"
  }, [!_vm.$route.query._board ? _c('h4', {
    staticClass: "tit tit--md"
  }, [_vm._v("소식지 등록")]) : _c('h4', {
    staticClass: "tit tit--md"
  }, [_vm._v("소식지 상세")])]), _c('div', {
    staticClass: "form--primary"
  }, [_vm.$route.params._board ? _c('form-row', {
    attrs: {
      "tit": "작성자"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": "",
      "outlined": ""
    }
  })], 1) : _vm._e(), _vm.$route.params._board ? _c('form-row', {
    attrs: {
      "tit": "실제 작성일"
    }
  }, [_c('v-text-field', {
    attrs: {
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": "",
      "outlined": ""
    },
    model: {
      value: _vm.$dayjs(_vm.board.createdAt).format('YYYY-MM-DD'),
      callback: function ($$v) {
        _vm.$set(_vm.$dayjs(_vm.board.createdAt), "format('YYYY-MM-DD')", $$v);
      },
      expression: "$dayjs(board.createdAt).format('YYYY-MM-DD')"
    }
  })], 1) : _vm._e(), _vm.$route.params._board ? _c('form-row', {
    attrs: {
      "tit": "최종수정일"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "'YYYY-MM-DD' 형식을 지켜주세요",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.updatedAt,
      callback: function ($$v) {
        _vm.$set(_vm.board, "updatedAt", $$v);
      },
      expression: "board.updatedAt"
    }
  })], 1) : _vm._e(), _c('form-row', {
    attrs: {
      "tit": "제목"
    }
  }, [_c('v-text-field', {
    attrs: {
      "persistent-placeholder": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "소식지 제목을  입력하세요."
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "파일"
    }
  }, [_c('v-file-input', {
    attrs: {
      "show-size": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "PDF 파일을 첨부하세요."
    },
    model: {
      value: _vm.board.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 0, $$v);
      },
      expression: "board.files[0]"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "썸네일 이미지"
    }
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*",
      "outlined": "",
      "hide-details": "",
      "placeholder": "썸네일 이미지를 첨부하세요."
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "이미지 미리보기"
    }
  }, [_vm.board.thumb ? _c('v-img', {
    staticClass: "border w-100",
    attrs: {
      "max-width": "282",
      "src": _vm.createObjectURL(_vm.board.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey-f5 border",
    attrs: {
      "max-width": "282",
      "aspect-ratio": 9 / 12
    }
  }, [_c('v-layout', {
    staticClass: "w-100 h-100 text-center",
    attrs: {
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-d--text"
  }, [_vm._v(" 권장 비율 ")]), _c('h4', {
    staticClass: "tit tit--md grey-d--text"
  }, [_vm._v(" 9:12 ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }