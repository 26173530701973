<template>
	<client-page>

        <sub-visual
        sh="소통과 참여"
        tabActive="산하시설소식"
        bg="/images/sub/visual/sv5.jpg">
        </sub-visual>

        <section class="section">
            <v-container>

                <!-- <tab-fair v-if="$route.query.mode !== 'input'"/> -->

                <news-write v-if="$route.query.mode === 'input'"/>
                <news-list v-else/>

            </v-container>
        </section>
        
	</client-page>
</template>

<script>

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
// import TabFair from "@/components/client/sub/tab-fair.vue";
import NewsList from "@/components/client/support/news-list.vue";
import NewsWrite from "@/components/client/support/news-write.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
        // TabFair,
		NewsList,
        NewsWrite
    },
}
</script>
